<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
    <section>
    
    <div class="fullscreen-bg-adjust" > 
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/videos%2Fviizziitransitions.mp4?alt=media&token=4d57218a-9429-401b-ae53-7f32942e635c">
        <source src="movie.ogg" type="video/ogg">
        Your browser does not support the video tag.
      </video>
      <div class="overlay-clear pad-fix black--text">
        <v-container>
          <div class="display-3">
            <span class="thin">Consumer</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Goods</span>
          </div>
        </v-container>
      </div>
    </div>
     </section>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Add wow to your product!</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <!-- 
            <div class="title mb-1">Fat Tuesday licensee added fun to its utilitarian drinkware</div>
            <v-divider class="my-3"></v-divider>
            -->
            <div class="title mb-1">Unique packaging with the ultimate “wow” factor</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Empower marketers to build laser-focused campaigns</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Bluetooth capable messaging</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="white">
    <v-container grid-list-xs fluid pa-0>
      <v-layout wrap>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-birthday.jpg?alt=media&token=462913ef-ee3c-4c8e-af12-738072a64b12"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/gallery-img7.jpg?alt=media&token=3ba76f2e-f3b6-48b9-b8f9-94c5950b4f18"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
        <v-flex
          xs4
        >
          <v-card flat tile>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/viizzii-pink-arrangement.jpg?alt=media&token=cec98587-a8dd-40e6-a5fe-faa137064883"
              :aspect-ratio="1"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Consumer Goods',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>